import './button.scss'


const Button = (prop) => {
    return (
        <button type="button" onClick={prop.onClick} style={{background: prop.background, color: prop.color, border: prop.border, width: prop.width}} className="button">
            {
            prop.text}
        </button> 
    )
}

export default Button

