import React from "react";
import { Routes, Route, Navigate } from 'react-router-dom'
import Cobranca from './pages/cobranca/Cobranca'
import { AnimatePresence } from "framer-motion"

function App() {

  return (
      <Routes>
        <Route path="*" element={<Navigate replace to="/" />} />
        <Route path='/cobranca/:id' element={<Cobranca />} />
      </Routes>
  );
}

export default App;
