import axios from 'axios'

/*SUMÁRIO
  1- criaBoletoData: Gerar boletos de cobrança
  2- procurarFechamento: Procura arquivo de fechamento
*/

const baseURL = '/cobranca'

const cobrancaApi = axios.create({
  baseURL: baseURL,
})

export const criaBoletoData = async (value) => {
  //Gerar boletos de cobrança

  //verifica se já foram geradas cobranças para o arquivo
  const resFind = await cobrancaApi.get('/procurarPorId', {params: {value:value}})

  if(resFind.data["boleto"] != ""){
    resFind["status"] = 204
    //se a cobrança já está registrada no banco de dados
    return resFind
  }

  //Gera boleto para a cobrança
  const res_cria_boleto = await cobrancaApi.post('/gerarBoletos', resFind.data)
  if(res_cria_boleto["status"] !== 201){
    //se houve algum erro ao gerar a cobrança
    
    return res_cria_boleto
  }

  return res_cria_boleto
}

export const procurarFechamento = async (value) => {
  //Procura arquivo de fechamento
  
  const res = await cobrancaApi.get('/procuraFechamento', {params: {value:value}})

  return res.data
}
