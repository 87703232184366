import React, { useState, useEffect } from 'react'
import { useParams} from 'react-router-dom';
import {useMutation, useQueryClient } from 'react-query'
import { Oval } from  'react-loader-spinner'
import { Icon } from '@mui/material';

import logo from "./imagens/logo.png";
import barcode from "./imagens/barcode_b.png"
import cartao from "./imagens/cartao.png"

import Button from '../../componentes/button/Button'
import PopUp from '../../componentes/popUp/PopUp'

import axios from 'axios'

import { criaBoletoData, procurarFechamento} from '../../api/cobrancaData'

import './cobranca.scss'

const Cobranca = () => {
  const {id} = useParams();

  const queryClient = useQueryClient()

  const baseURL = '/cobranca'
  const cobrancaApi = axios.create({
    baseURL: baseURL,
  })

  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [boleto, setboleto] = useState("Gerar Boleto");
  const [codicoBoleto, setCodicoBoleto] = useState("");
  const [buttonPopUpFilter, setButtonPopUpFilter] = useState(false);
  const [colorStatus, setcolorStatus] = useState("#df9035");

  useEffect(() => {

    buscarDados();
  }, [setboleto, setCodicoBoleto, setData]);

  const buscarDados = async () => {
    //Função procura cobranças no banco de dados
    var resposta
    try {
      resposta =  await cobrancaApi.get('/procurarPorId', {params: {value:id}})
    } catch(err) {
      return console.error(err);
    }

    //Formata data
    const opcoesLocalizacao = {
        timeZone: 'America/Sao_Paulo',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    };

    var date = new Date(resposta.data["Vencimento da Cobranca"].split("Z")[0]);
    var dataHoraBrasileira = date.toLocaleString('pt-BR', opcoesLocalizacao);
  
    resposta.data["Vencimento da Cobranca"] = dataHoraBrasileira

    setData(resposta.data)
    setCodicoBoleto(resposta.data.boleto.barcode)
    switch(resposta.data.status){
      case "PAGO":
        setcolorStatus("rgb(66, 175, 60")
        break
      case "Inadimplente":
        setcolorStatus("#c84333")
        break
    }
  };

  const gerarBoleto = () =>{
    //Função gera boleto]

    if(data.status == "Inadimplente"){
      alert("Desculpe, mas a opção de pagamento por boleto não está disponível no momento. Você pode efetuar o pagamento apenas através do Pix. Se precisar de assistência para efetuar o pagamento por boleto, por favor, entre em contato com o departamento financeiro da Taon Delivery.")
      return
    }
    if(data.boleto != ''){
      alert("Já foi criado um boleto para esta cobrança.")
      
    }else{
      setButtonPopUpFilter(true)
    }
  }

  const pagamentoCartao = () =>{
    //Função de pagamento com cartão

    if(data.status == "Inadimplente"){
      alert("Desculpe, mas a opção de pagamento por boleto não está disponível no momento.Você pode efetuar o pagamento apenas através do Pix. Se precisar de assistência para efetuar o pagamento por boleto, por favor, entre em contato com o departamento financeiro da Taon Delivery.")
      return
    }
    alert("Ops, infelizmente ainda não está habilitado o pagamento em cartão, logo estará. Por enquanto você consegue pagar as corridar por boleto ou pix.")
  }

  const lidarBoleto =  () =>{
    //Chama end-point de criação de boleto

    setButtonPopUpFilter(false)
    setLoader(true)
    criaBoleto.mutate(id)
  }
  
  const lidarBaixarFechamento = async(event) =>{
    //Chama end-point para baixar os fechamos

    setLoader(true)
    baixarFechamento.mutate(data["ID da Empresa"])
  }

  const lidarCopiarQrCode = async(event) =>{
    //Copia qr-code

    await navigator.clipboard.writeText(data.emv_payload);
  }

  const lidarCopiarCodigoBarra = async () =>{
    //Copia qr-codigo de baraa

    await navigator.clipboard.writeText(data.boleto.barcode);
  }

  const lidarBaixarBoleto = () => { 
    //Baixar boleto

    var link = document.createElement('a');
    link.href = data.boleto.pdf.charge;
    link.download = 'Boleto.pdf';
    link.dispatchEvent(new MouseEvent('click'));
  } 

  const atualizaPagina = async () =>{
    //atualiza pagina

    window.location.reload(false);
  }

  const baixarFechamento =  useMutation(procurarFechamento, {
    onSuccess: (e) => {
      queryClient.invalidateQueries()
  
      if (e["status"] == 200 && e.data != null) {

        let blob1 = new Blob([new Uint8Array(e.data.buffer.data)],{type:'application/pdf'})

        let aDom = document.createElement('a')
          aDom.type = 'download'
          aDom.href = URL.createObjectURL(blob1)
          aDom.download = 'Fechamento.pdf'
          aDom.click()

        setLoader(false)

      }else{
        setLoader(false)
        alert("Arquivo não encontrado.")
      }
    },
    onError: (e) => {
      setLoader(false)
      alert(e.response.data)
    }
  });


  const criaBoleto =  useMutation(criaBoletoData, {
    onSuccess: (e) => {
      queryClient.invalidateQueries()

      if (e["status"] === 204) {
        setLoader(false)
        alert("Erro: Já foram gerados boletos para esta cobrança.")
      } 
      if(e["status"] === 201){
        setLoader(false)
        setboleto("")
        setCodicoBoleto(e.data.barcode)
        alert("Boleto gerado com sucesso.")
        atualizaPagina()
      
      }else {
        setLoader(false)
        alert(e)
      }
    },
    onError: (e) => {
      setLoader(false)
      alert(e.response.data)
    }
  });

  return (
    <div className='finance'
    >
      <div className='header-cobranca'>
        <img className="icone" src={logo}/>
        <div className="faixa"></div>
      </div>
     { data.length != 0 &&
      <div className="bloco">
        <div className='status-cobranca'>
          <div className='resumo-status'>
            Status:
            <div className='bota-status-cobranca' style={{background: colorStatus, border: "1px solid "+colorStatus}}> 
            {data.status == "ATIVA" &&
              "Aguardando"
            }
            {data.status != "ATIVA" &&
              data.status
            } </div>
          </div>
          </div>
        <div className='blocos'>
          { loader == true &&
            <div className='loader'>
              <Oval height={50} width={50} color="#6B4380" wrapperStyle={{}} wrapperClass="" visible={loader} ariaLabel='oval-loading' secondaryColor="#6B4380" strokeWidth={3} strokeWidthSecondary={3} />
            </div>
          }
          <div className='formulario'>
            <div className='items'>
              <div className='topico'>Cliente</div>
              <div className='topico2'>{data["Nome do Estabelecimento"]}</div>
            </div>
            <div className='items'>
              <div className='topico'>CNPJ</div>
              <div className='topico2'>{data["CNPJ"]}</div>
            </div>
            <div className='items'>
              <div className='topico'>Validade</div>
              <div className='topico2'>
                {data.length != 0 &&
                  data["Vencimento da Cobranca"] 
                }
              </div>
            </div>
            <div className='items'>
              <div className='topico'>Valor da Cobrança</div>
              <div className='topico2'>{"R$ " + parseFloat(data["Valor da Cobranca"]).toFixed(2)}</div>
            </div>
            <div className='items'>
              <div className='topico'>Descrição da Cobrança</div>
              <div className='topico2'>{ data["Descricao da Cobranca"]}</div>
            </div>
            <div className='items'>
              <div className='topico'>Informações</div>
              <div className='topico2'>{"Após a data de vencimento será cobrado um juros fixo de 3% e outro de 0,33% por dia de atraso."}</div>
            </div>
            {
              <Button onClick={() => {lidarBaixarFechamento()}} width={"150px"} color={"#FEFCFF"} background={'#6B4380'} border={'1px solid #6B4380'} text={"Baixar Relatório"} />
            }
          </div>
            {data.length != 0 && data.status != "PAGO" &&
              <div className='qrcode'>
                <div> Informamos que o Banco do Brasil e o Santander estão enfrentando dificuldades na leitura do QR Code. Portanto, solicitamos que o pagamento desta semana seja efetuado via Pix utilizando a chave 33.292.501/0001-48.</div>
                <img src={"data:image/png;base64," +  data.image_base64} />
                <Button onClick={() => {lidarCopiarQrCode()}} width={"150px"} color={"#FEFCFF"} background={'#6B4380'} border={'1px solid #6B4380'} text={"Copiar QR Code"} />
              </div>
            }
            {data.status == "PAGO" &&
              <div className='qrcode'  style={{marginLeft:"40px"}}>
                <Icon sx={{ fontSize: 100, color:"rgb(66, 175, 60)" }}>done_all</Icon>
                <div  style={{color:"rgb(66, 175, 60)", fontWeight: "bold", fontFamily: "sans-serif"}}> Pagamento confirmado.</div>
              </div>
            }
        </div>
        <div className='pagamentos'>
        { data.status != "PAGO" &&
          <div className='formas-pagamento'>  
            Outras formas de pagamento
          </div>
        }
        {data.status != "PAGO" &&
            <div className='boleto'>
              { data.boleto == '' &&
                <div className='boleto-button' onClick={() => {gerarBoleto()}} >        
                  <img src={barcode}  width={24} height={24} />
                  <div className='text-boleto'>
                  {"Boleto"}
                  </div>
                </div>
              }
              <div className='boleto-button' onClick={() => {pagamentoCartao()}} >        
                <img src={cartao}  width={24} height={24} />
                <div className='text-boleto'>
                {"Cartão"}
                </div>
              </div>
            </div>
        }
        {data.boleto != '' &&  data.status != "PAGO" &&
          <div className='codigo-boleto'  >
            <div className='boleto-copi'>
              {"Código boleto: "+ codicoBoleto}
              <div className='botao-copiar' onClick={() => {lidarCopiarCodigoBarra()}}>
                <Icon sx={{ fontSize: 15 }}>content_copy</Icon>
              </div>
            </div>
            <Button  onClick={() => {lidarBaixarBoleto()}} width={"100px"} color={"#FEFCFF"} background={'#df9035'} border={'1px solid #df9035'} text={"Baixar boleto"} />
          </div>
        } 
        </div>
      </div>
      }
      <div className='pe'>
        <div className="faixa-final"></div>
      </div>
      <PopUp trigger={buttonPopUpFilter} setTrigger={setButtonPopUpFilter}>
        <div className='popupBoleto'>
          {
            "Observe que ao optar pelo pagamento via boleto, será acrescentada uma taxa de R$ 2,50 devido às despesas de emissão. Por outro lado, o pagamento via Pix não incorre em taxas adicionais e mantém o valor original. Você tem a certeza de que deseja continuar?"
          }
          <div className='botaoGerarBoleto'>
            <Button onClick={() => {setButtonPopUpFilter(false)}} width={"70px"} color={"#ffffff"} background={"#c84333"} border={"1px solid #c84333"} text={"Cancelar"} />
            <Button onClick={() => {lidarBoleto()}} width={"60px"} color={"#ffffff"} background={"#6B4380"} border={"1px solid #6B4380"} text={"Aceitar"} />
          </div>
        </div>
      </PopUp>
    </div>
  )
}

export default Cobranca
