import React from 'react'
import './popUp.scss'

const PopUp = (props) => {
    return (props.trigger) ? (
        <div className='popup'>
            <div className='popup-inner'>
            <i className="material-icons" onClick={() => props.setTrigger(false)}>close</i>
                {props.children}

            </div>
        </div>
    ): "";

}

export default PopUp